'use client';

import Image from 'next/image';

import defaultLoginBanner from '@/public/default-login.png';
import { useGetClient } from '@/services/use-get-client';

export const LoginBanner = () => {
    const { client } = useGetClient();
    const loginImage = client?.branding?.loginImage?.url;

    return (
        <div className="bg-primary-600 relative hidden flex-1 lg:flex">
            <Image
                alt="Onboarding Background Image"
                className="shrink-0 object-cover object-center"
                draggable={false}
                fill
                priority
                quality={100}
                sizes="50vw"
                src={loginImage ?? defaultLoginBanner.src}
                unoptimized
            />
        </div>
    );
};
