'use client';

import { LogoTextNew } from '@in2event/icons';
import Image from 'next/image';

import { useGetClient } from '@/services/use-get-client';

export const LoginLogo = () => {
    const { client } = useGetClient();
    const loginLogoImage = client?.branding?.organizationLogo?.url;

    return (
        <div className="absolute left-9 top-9 mb-12 hidden h-9 w-[7.5rem] lg:block">
            {loginLogoImage ? (
                <Image
                    alt="Onboarding Background Image"
                    className="shrink-0 rounded-xl object-cover object-center"
                    draggable={false}
                    priority
                    quality={100}
                    sizes="50vw"
                    src={loginLogoImage}
                    width={64}
                    height={64}
                    unoptimized
                />
            ) : (
                <LogoTextNew aria-hidden="true" className="h-9 w-[7.5rem]" viewBox="0 0 160 41" />
            )}
        </div>
    );
};
